import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainerGridTwoCols, SectionWrapperSmall } from "../components/styles/Sections"
import Footer from "../components/footer"
import BreadCrumbs from "../components/breadcrumbs"
import Bio from "../components/bio"

const AuthorIndex = ({
  data,
  location,
}) => {
  const authors = data.allWpUser.nodes;
  const archiveName = location.pathname.replaceAll('/', '');

  /* CUSTOM ARRAY TO RENDER BREADCRUMBS AS IT IS AN ARCHIVE PAGE */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  const crumbs = [ 
    {
    "text": "Hem",
    "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": capitalizeFirstLetter(archiveName),
      "url": "/" + archiveName
    }
  ]

  const seoTitle = "Våra skribenter - Läs mer om redaktionen bakom Svenskaonlinecasinon.se ";
  const metaDescription = "Här hittar du teamet bakom Svenskaonlinecasinon.se som uppdaterar vår sida med det senaste inom branschen."

  return (
    <Layout isHomePage>
      <Seo title={seoTitle} description={metaDescription} />
      <BreadCrumbs items={crumbs} hideDate={true} />

      <SectionWrapperSmall>
        <h1>Våra skribenter</h1>
        <p>Läs mer om teamet bakom Svenskaonlinecasinon.se som underhåller sidan och ser till att våra användare alltid får tillgång de senaste recensionerna, guiderna och nyheterna för online casinon.</p>
        <SectionContainerGridTwoCols>
          {authors.map((item) => (
            <Bio author={item} />
          ))}
        </SectionContainerGridTwoCols>
      </SectionWrapperSmall>
      <Footer />
    </Layout>
  )
}

export default AuthorIndex

export const pageQuery = graphql`
  query WordPressAuthorArchive {
    allWpUser {
      nodes {
        name
        slug
        uri
        description
        avatar {
          url
        }
        authorSocials {
          linkedin
        }
      }
    }
  }
`
